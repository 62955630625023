*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

html {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: europa, helvetica, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
}

body {
  overscroll-behavior: none;
}

h1 {
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 2.5rem;
}

@media (width >= 640px) {
  h1 {
    font-size: 3rem;
    line-height: 1;
  }
}

h2 {
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 2.25rem;
}

@media (width >= 640px) {
  h2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

h3 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
}

@media (width >= 640px) {
  h3 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

h4 {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75rem;
}

@media (width >= 640px) {
  h4 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

h5 {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
}

@media (width >= 640px) {
  h5 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

h6 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

@media (width >= 640px) {
  h6 {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

strong {
  font-weight: 700;
}

blockquote {
  color: #0009;
  border-color: #0000001a;
  border-left-width: 4px;
  padding-left: 1.25rem;
  font-style: italic;
  font-weight: 700;
  position: relative;
}

code:before, code:after {
  content: "`";
}

pre {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .75rem;
  padding: .75rem 1.5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  overflow-x: scroll;
}

pre code:before, pre code:after {
  content: "";
}

table {
  width: 100%;
}

table tr {
  border-color: #0000001a;
  border-top-width: 1px;
}

table th, table td {
  text-align: left;
  vertical-align: top;
  padding-top: .75rem;
  padding-bottom: .75rem;
  padding-right: .75rem;
}

figure:not(.media):not(.table) {
  max-width: fit-content;
}

figcaption {
  color: #0009;
}

hr {
  border-color: #0000001a;
}

.richtext * {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.richtext > :first-child {
  margin-top: 0;
}

.richtext > :last-child {
  margin-bottom: 0;
}

:is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h1, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h2, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h3, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h4, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h5, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h6, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + hr {
  margin-top: 3rem;
}

.richtext h1 a, .richtext h2 a, .richtext h3 a, .richtext h4 a, .richtext h5 a, .richtext h6 a, .richtext p a, .richtext li a, .richtext blockquote a {
  text-underline-offset: 4px;
  text-decoration-line: underline;
  text-decoration-color: currentColor;
  text-decoration-thickness: 1px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.richtext h1 a:hover, .richtext h2 a:hover, .richtext h3 a:hover, .richtext h4 a:hover, .richtext h5 a:hover, .richtext h6 a:hover, .richtext p a:hover, .richtext li a:hover, .richtext blockquote a:hover {
  text-decoration-color: #0000;
}

.richtext h1 a, .richtext h2 a, .richtext h3 a, .richtext h4 a, .richtext h5 a, .richtext h6 a, .richtext p a, .richtext li a, .richtext blockquote a {
  overflow-wrap: break-word;
}

.richtext ul, .richtext ol {
  padding-left: 1.25rem;
}

.richtext ul > li {
  margin-top: .75rem;
  margin-bottom: .75rem;
  list-style-type: disc;
}

.richtext ul > li::marker {
  color: #0006;
}

.richtext ol > li {
  margin-top: .75rem;
  margin-bottom: .75rem;
  list-style-type: decimal;
}

.richtext figcaption {
  margin-top: -.75rem;
}

.lazyload, .lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .7s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

img.lazyload:not([src]) {
  visibility: hidden;
}

figure.media div {
  margin: 0;
  position: relative;
}

figure.media iframe {
  margin: 0;
  position: absolute;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1040px) {
  .container {
    max-width: 1040px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: .75rem;
  padding-right: .75rem;
}

@media (width >= 640px) {
  .container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 768px) {
  .container {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

.button {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  --tw-gradient-from: #ff5131 var(--tw-gradient-from-position);
  --tw-gradient-to: #ff685c var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: 9999px;
  font-size: 1.125rem;
  line-height: 1.75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.button:hover {
  --tw-brightness: brightness(.75);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.label {
  margin-bottom: .5rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
  display: block;
}

label.required:after {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
  --tw-content: "*";
  content: var(--tw-content);
  margin-left: .25rem;
}

.input {
  appearance: none;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-width: 1px;
  border-color: #0000001a;
  border-radius: .125rem;
  width: 100%;
  padding: .5rem .75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
}

.input::placeholder {
  color: #0009;
}

.input:hover {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.input:focus {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.bottom-3 {
  bottom: .75rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.left-0 {
  left: 0;
}

.left-2\/4 {
  left: 50%;
}

.left-3 {
  left: .75rem;
}

.right-2\/4 {
  right: 50%;
}

.top-0 {
  top: 0;
}

.top-6 {
  top: 1.5rem;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.-mb-4 {
  margin-bottom: -1rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-auto {
  margin-bottom: auto;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-4 {
  height: 1rem;
}

.h-8 {
  height: 2rem;
}

.h-\[300px\] {
  height: 300px;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.min-h-screen {
  min-height: 100vh;
}

.\!w-10 {
  width: 2.5rem !important;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-2\/4 {
  width: 50%;
}

.w-3 {
  width: .75rem;
}

.w-32 {
  width: 8rem;
}

.w-4 {
  width: 1rem;
}

.w-8 {
  width: 2rem;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-max {
  max-width: max-content;
}

.max-w-prose {
  max-width: 65ch;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.-translate-x-3\/4 {
  --tw-translate-x: -75%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-3\/4 {
  --tw-translate-x: 75%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.resize {
  resize: both;
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-12 {
  gap: 3rem;
}

.gap-24 {
  gap: 6rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-y-1 {
  row-gap: .25rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.gap-y-3 {
  row-gap: .75rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-white\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ffffff4d;
}

.overflow-hidden {
  overflow: hidden;
}

.\!rounded-lg {
  border-radius: .5rem !important;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-b-2xl {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.border {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.\!border-black {
  --tw-border-opacity: 1 !important;
  border-color: rgb(0 0 0 / var(--tw-border-opacity)) !important;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-black\/30 {
  border-color: #0000004d;
}

.border-blue-light {
  --tw-border-opacity: 1;
  border-color: rgb(154 224 255 / var(--tw-border-opacity));
}

.border-white\/30 {
  border-color: #ffffff4d;
}

.bg-\[gold\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 215 0 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-black\/10 {
  background-color: #0000001a;
}

.bg-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(51 26 153 / var(--tw-bg-opacity));
}

.bg-blue-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(11 28 64 / var(--tw-bg-opacity));
}

.bg-blue-pale {
  --tw-bg-opacity: 1;
  background-color: rgb(88 118 172 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-grey {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity));
}

.bg-purple {
  --tw-bg-opacity: 1;
  background-color: rgb(236 234 255 / var(--tw-bg-opacity));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-none {
  background-image: none;
}

.from-black {
  --tw-gradient-from: #000 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-blue {
  --tw-gradient-to: #331a9900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #331a99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.to-orange {
  --tw-gradient-to: #ff5131 var(--tw-gradient-to-position);
}

.fill-current {
  fill: currentColor;
}

.fill-red-100 {
  fill: #fee2e2;
}

.fill-white {
  fill: #fff;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.p-3 {
  padding: .75rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-30 {
  opacity: .3;
}

.opacity-40 {
  opacity: .4;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.preload .transition {
  transition-property: none !important;
}

.gradient {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  --tw-gradient-from: #000 var(--tw-gradient-from-position);
  --tw-gradient-to: #ff5131 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #331a99 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.border-gradient {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  --tw-gradient-from: #000 var(--tw-gradient-from-position);
  --tw-gradient-to: #ff5131 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #331a99 var(--tw-gradient-via-position), var(--tw-gradient-to);
  border-radius: .5rem;
  padding: .25rem;
}

.border-gradient > div {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: .375rem;
}

.animate {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .7s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.animate[data-animation="fadeUp"] {
  --tw-translate-y: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 0;
}

.animated.fadeUp {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 1;
}

.placeholder\:text-black\/50::placeholder {
  color: #00000080;
}

.hover\:text-orange:hover {
  --tw-text-opacity: 1;
  color: rgb(255 81 49 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.focus\:translate-y-0:focus {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:border-blue-light {
  --tw-border-opacity: 1;
  border-color: rgb(154 224 255 / var(--tw-border-opacity));
}

@media (width >= 640px) {
  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:block {
    display: block;
  }

  .sm\:h-auto {
    height: auto;
  }

  .sm\:w-2\/3 {
    width: 66.6667%;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@media (width >= 768px) {
  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .md\:mb-12 {
    margin-bottom: 3rem;
  }

  .md\:mt-24 {
    margin-top: 6rem;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .md\:text-right {
    text-align: right;
  }
}

@media (width >= 1024px) {
  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }
}

@media (width >= 1040px) {
  .nav\:static {
    position: static;
  }

  .nav\:absolute {
    position: absolute;
  }

  .nav\:mt-0 {
    margin-top: 0;
  }

  .nav\:block {
    display: block;
  }

  .nav\:flex {
    display: flex;
  }

  .nav\:hidden {
    display: none;
  }

  .nav\:h-auto {
    height: auto;
  }

  .nav\:w-auto {
    width: auto;
  }

  .nav\:w-max {
    width: max-content;
  }

  .nav\:translate-y-4 {
    --tw-translate-y: 1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .nav\:rounded-lg {
    border-radius: .5rem;
  }

  .nav\:bg-transparent {
    background-color: #0000;
  }

  .nav\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .nav\:fill-orange {
    fill: #ff5131;
  }

  .nav\:p-4 {
    padding: 1rem;
  }

  .nav\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .nav\:pt-0 {
    padding-top: 0;
  }

  .nav\:pt-12 {
    padding-top: 3rem;
  }

  .nav\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .nav\:text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }

  .group:focus-within .group-focus-within\:nav\:block, .group:hover .group-hover\:nav\:block {
    display: block;
  }
}
/*# sourceMappingURL=main.css.map */
